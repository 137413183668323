import React, {useRef, useState} from "react";
import { CommonInputProps } from "../interfaces/types";
import { useCustomTranslation } from '@utils/translations'; 
export type InputFieldProps = React.ComponentProps<"input">;
export type InputProps = CommonInputProps & InputFieldProps;
import Image from 'next/image';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';

export const Input = (props: InputProps) => {
  const {
    name,
    label = "",
    error,
    className = "",
    parentClassName = "",
    required = props.required ? props.required : false,
    type,
    showRemoveIcon,
    showCheck,
    datepicker,
    classEmptyIcon,
    onRemoveValueCallback,
    handleEmailBlur,
    handleEmailFocus,
    forceWhiteBgInputLabel,
    subQuestion,
    autoFocus,
    ...rest
  } = props;
  const { t } = useCustomTranslation();
  const [showPass, setShowPass] = useState<boolean>(false);
  const [emptyInput, setEmptyInput] = useState<Record<string, any>>({isVisible: false, inputName: name});
  const inputProps = rest as InputFieldProps;
  const refElement = useRef<HTMLInputElement>(null);

  const removeValue = () => {
    setEmptyInput((prevValue) => ({
      ...prevValue,
      [name]: {isVisible: !emptyInput.isVisible, inputName: name}
    }));

    if (onRemoveValueCallback) {
      onRemoveValueCallback(name);
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    setTimeout(() => {
      if (name === 'email') {
        handleEmailBlur(e, false);
      }
      setEmptyInput({ isVisible: false, inputName: name });
      if (props.onBlur) {
        props.onBlur(e);
      }
    }, 200);
  }

  const handleFocus = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    // @ts-ignore
    if (name === 'email' && inputProps.value.includes('@')) {
      handleEmailFocus(true);
    }
    setEmptyInput({ isVisible: true, inputName: name })
    if (props.onFocus) {
      props.onFocus(e);
    }
  }
  
  return (
    <>
      <div className={`text-gray-900 ${inputProps.placeholder ? "w-full" : "relative"} group  ${subQuestion ? 'bg-[#FAFAFA]' : 'bg-transparent md:bg-white'} ${parentClassName}`}>
        {inputProps.placeholder ? (
          <input
            ref={refElement}
            id={name}
            name={name}
            type={type}
            className={`${className}`}
            {...inputProps}
            required={required}
          />
        ) : (
            <input
            autoFocus={autoFocus ? true : false}
            ref={refElement}
            id={name}
            name={name}
            type={showPass ? 'text' : type}
            className={`${props.value ? 'peer': ''} ${type == 'password' && !showPass ? 'tracking-[8px]' : ''} ${emptyInput?.isVisible ? '' : 'px-[18px]'} ltr:pl-[18px] ltr:pr-[45px] rtl:pr-[18px] rtl:pl-[45px] w-full text-sm focus:ring-0 focus:border-gray text-gray-900 dark:text-white autofill:bg-white autofill:text-gray-900 rounded-[5px] border-[1px] border-solid border-[#D3D5D6] h-13 py-[15px] relative z-[2]  bg-transparent ${
              error && Object.keys(error).length ? "invalidInput" : ""
            } ${className ? className : ""}`}
            {...inputProps}
            required={required}
            onFocus={handleFocus}
            onBlur={(e) => handleBlur(e)}
          />
        )}
        {label && (
          <label
            htmlFor={name}
            className={`ltr:pr-9 ltr:text-left  rtl:pl-9 rtl:text-right transform text-gray translate-y-0 h-5 dark:bg-dark-mode px-1 transition-all absolute ${name === 'promoCode' ? 'top-[10px] ltr:left-[16px] rtl:right-[16px]' : 'top-[10px] ltr:left-[18px] rtl:right-[18px]'} flex items-center z-[1] ${name === 'promoCode' ? 'text-xs md:text-sm' : 'text-sm'} group-focus-within:text-xs group-focus-within:ltr:pr-1 group-focus-within:rtl:pl-1 peer-valid:ltr:pr-1 peer-valid:rtl:pl-1 peer-valid:text-xs peer-invalid:text-xs 
                ${forceWhiteBgInputLabel ? 'bg-white group-focus-within:bg-white'
                : subQuestion ?  
                `${props?.value ? 'bg-linearGradientInputLightGrayInverted md:bg-linearGradientInputLightGray' : 'group-focus-within:bg-linearGradientInputLightGrayInverted md:group-focus-within:bg-linearGradientInputLightGray'}`
                : props?.value ? 'bg-white md:bg-linearGradientInput'
                : props?.value && forceWhiteBgInputLabel ? 'bg-white'
                : 'bg-transparent group-focus-within:bg-white md:group-focus-within:bg-linearGradientInput'}
                h-[32px] group-focus-within:h-[16px] peer-valid:h-[16px] peer-invalid:h-[16px]
                ltr:group-focus-within:left-[12px] rtl:group-focus-within:right-[12px] ltr:peer-valid:left-[12px] rtl:peer-valid:right-[12px] ltr:peer-invalid:left-[12px] rtl:peer-invalid:right-[12px]
                group-focus-within:top-[8px] peer-valid:top-[8px] peer-invalid:top-[8px] group-peer-empty:top-[8px]
                group-focus-within:z-[3] peer-valid:z-[3] peer-invalid:z-[3]
                group-focus-within:-translate-y-full peer-valid:-translate-y-full peer-invalid:-translate-y-full`}
          >
            {label}
          </label>
        )}
        {inputProps.value !== "" && showCheck && !emptyInput?.isVisible && (!error || Object.keys(error).length == 0)  && type !== 'password' && (
          <Image className={`absolute top-[14px] ltr:right-[15px] rtl:left-[15px] z-10`} width='24' height='24' src='/tick-circle.svg' alt=''/>
        )}
        {inputProps.value !== "" && type === 'password' && (
          <span className={`absolute ltr:right-[15px] rtl:left-[15px] top-[14px] w-[25px] cursor-pointer z-10`} onClick={() => setShowPass(!showPass)}>
            {showPass ? (
                <EyeSlashIcon width={25} className={`cursor-pointer`} style={{ color: '#999' }} />
            ) : (
                <EyeIcon width={25} className={`cursor-pointer`} style={{ color: '#999' }} />
            )}
          </span>
        )}
        {inputProps.value !== "" && emptyInput?.isVisible && emptyInput?.inputName === name && name !== 'password' && onRemoveValueCallback &&  (
            <Image className={`${classEmptyIcon ? classEmptyIcon : 'absolute top-[14px] ltr:right-[15px] rtl:left-[15px] cursor-pointer z-[11] bg-white'}`} width='24' height='24' src='/close-circle.svg' alt='' onClick={removeValue} />
        )}
      </div>
    </>
  );
};
