import { Input } from '../Input/Input';
import React, { useEffect, useRef } from 'react';
import { useCustomTranslation } from '@utils/translations';
import { InputTypes } from '../utils/validate';
import Link from 'next/link';
import { useWindowScroll } from '@mantine/hooks';

type EmailInputProps = {
    errors: Record<string, any>;
    values: string;
    errorMessage: string;
    showEmailSuggestions: boolean;
    onRemove: (inputName: string) => void;
    handleChange: (ev: React.ChangeEvent<InputTypes>, inputSchema: any) => void;
    onBlur: (e: React.FocusEvent<HTMLInputElement, Element>, a: boolean) => void;
    onFocus: () => void;
    handleEmailDomain: (domain: { id: number | null; value: string | null; }) => void;
    validationSchema: Record<string, any>;
    domains?: string[];
};

const EmailSuggestion = (props: EmailInputProps) => {
    const listOfDomains = ['gmail.com', 'yahoo.com', 'outlook.com', 'hotmail.com', 'aol.com', 'mail.com'];
    let { domains } = props;
    const {
        values,
        errors,
        errorMessage,
        showEmailSuggestions,
        validationSchema,
        onRemove,
        onBlur,
        onFocus,
        handleChange,
        handleEmailDomain
    } = props;
    const { t } = useCustomTranslation();
    if (!domains) {
        domains = listOfDomains;
    }

    const uniqueId = () => {
        const crypto = window.crypto;
        // @ts-ignore
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c: number) =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>, a: boolean) => {
        onBlur(e, a);
    }

    const renderedId = uniqueId();

    const [scroll, scrollTo] = useWindowScroll();

    const scrollRef = useRef<HTMLDivElement>(null);
    const pos = scrollRef?.current?.getBoundingClientRect();

    useEffect(() => {
        if (errorMessage && errorMessage === 'register.email_already_exists') {
            scrollTo({ y: pos?.top })
        }
    }, [errorMessage]);



    return (
        <div ref={scrollRef}>
            <Input

                className={``}
                forceWhiteBgInputLabel
                value={values}
                onChange={(e) => handleChange(e, validationSchema)}
                handleEmailBlur={handleBlur}
                handleEmailFocus={onFocus}
                onRemoveValueCallback={onRemove}
                showCheck={true}
                type="text" id="email" name="email" label={t("login.email")}
                list={renderedId}
                error={errors} required />
            {errorMessage && errorMessage === 'register.email_already_exists' && (
                <p id="err_email_exists" className="text-red text-left text-sm mt-1">
                    {t(errorMessage)}
                    <Link className="font-semibold text-blue hover:underline pl-2" href={`/login?email=${values}`}>
                        {t("register.sign_in")}
                    </Link>
                </p>

            )}

            {errors && (
                <p id="err_email" className="text-red text-left text-sm mt-1">
                    {t(errors?.message)}
                </p>

            )}

            {showEmailSuggestions && (
                <div id={renderedId} className={`absolute top-[55px] left-[149px] px-3 py-3 z-10 mt-1 origin-top-right rounded-md bg-white shadow-lg focus:outline-none dark:bg-dark-mode-navbar transform opacity-100 scale-100`}>
                    <span className="absolute -top-[7px] z-10 before:h-0 w-0 border-x-8 border-x-transparent border-b-[8px] border-b-white dark:border-b-dark-mode-navbar" style={{ left: 'calc(50% - 8px)' }} />
                    {(values === '' ? '' : domains.slice(0, domains.length).map((domain: any, index: React.Key | null | undefined) => {
                        return <a onClick={() => handleEmailDomain(domain)} className={`group flex items-center px-2 py-2 text-sm cursor-pointer rounded-md text-gray-700 dark:text-white hover:bg-gray-100 hover:text-black border-transparent border-0`} key={index}>{domain}</a>
                    }))}
                </div>
            )}
        </div>
    );
};
export default EmailSuggestion;
