import {AnySchema} from 'yup';

export type InputTypes =
    | HTMLInputElement
    | HTMLTextAreaElement
    | HTMLSelectElement;
export type ErrorFields<T> = Partial<Record<keyof T, string>>;
export type TouchedFields<T> = Partial<Record<keyof T, boolean>>;
export type YupSchemaObject<T> = Record<keyof T, AnySchema>;

export const getInputValue = (input: EventTarget & HTMLInputElement) => {
    switch (input.type) {
        case 'file':
            return input.files;
        case 'checkbox':
            return input.checked;
        default:
            return input.value;
    }
};

const validate = (field: any, formSchema: any) => {
    // @ts-ignore
    return formSchema?.validate(field, {abortEarly: false}).catch(({ errors }) => {
        const validationErrors = errors.reduce((acc: { [x: string]: any; }, error: { [s: string]: any; } | ArrayLike<any>) => {
            const [key, value] = Object.entries(error)[0];
            acc[key] = value;
            return acc;
        }, {});
        return Promise.resolve({ errors: validationErrors });
    });
};

export default validate;
