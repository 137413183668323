import styles from './RegisterLoginRightSection.module.css';
import graph from './../../public/short-registration-image-light.png';
import graphDark from '../../public/short-registration-image-dark.png';
import checkmarkGrey from './../../public/checkmark-grey.png';
import darkBoard from './../../public/Dark_board_clear_2x.png';
import lightBoard from './../../public/Light_board_clear_2x.png';
import candles from './../../public/candles_wide.png';
import goldTab from './../../public/Light_gold_asset_2x.png';
import { useCustomTranslation } from '@utils/translations'; 
import Image from "next/image";
import HTMLRender from '../../components/utils/htmlrender';


interface RightSectionProps {
  defaultLicenseName: string;
  productType?: string;
}
const RightSection = ({ defaultLicenseName, productType }: RightSectionProps) => {
  const { t } = useCustomTranslation();

  return (
    <div className={styles.rightSectionWrapper}>
      <div className={styles.textWrapper}>
        <h3 className={`text-gray-800 font-gilroy font-bold text-[22px] leading-[1.1] ${styles.textSpacing}`}><HTMLRender data={t(`rightsection.title`)}/></h3>
        <p className={`text-sm col-span-1 flex flex-row items-start  ${styles.textSpacing}`}>
          <Image
            src={checkmarkGrey.src}
            alt="checkmark"
            width="16"
            height="16"
            sizes="100vw"
            className="inline-block ltr:mr-2 rtl:ml-2  h-[16px]"
          />
        <HTMLRender data={t(`rightsection.${defaultLicenseName}.${productType}.text_one`)} />
        </p>
        <p className={`text-sm col-span-1 flex flex-row items-start ${styles.textSpacing}`}>
          <Image
            src={checkmarkGrey.src}
            alt="checkmark"
            width="16"
            height="16"
            sizes="100vw"
            className="inline-block ltr:mr-2 rtl:ml-2  h-[16px]"
          />
           <HTMLRender data={t(`rightsection.${defaultLicenseName}.${productType}.text_two`)} />
        </p>
        <p className={`text-sm col-span-1 flex flex-row items-start ${styles.textSpacing}`}>
          <Image
            src={checkmarkGrey.src}
            alt="checkmark"
            width="16"
            height="16"
            sizes="100vw"
            className="inline-block ltr:mr-2 rtl:ml-2  h-[16px]"
          />
           <HTMLRender data={t(`rightsection.${defaultLicenseName}.${productType}.text_three`)} />
        </p>
        <p className="text-sm col-span-1 flex flex-row items-start">
          <Image
            src={checkmarkGrey.src}
            alt="checkmark"
            width="16"
            height="16"
            sizes="100vw"
            className="inline-block ltr:mr-2 rtl:ml-2  h-[16px]"
          />
           <HTMLRender data={t(`rightsection.${defaultLicenseName}.${productType}.text_four`)} />
        </p>
      </div>

      <div className={styles.imagesWrapper}>
        <div className={styles.darkBoard}>
          <Image
            src={darkBoard.src}
            alt="Capex Platform Dark"
            width="0"
            height="0"
            sizes="100vw"
            className={styles.darkBoardImg}
          />
        </div>
        <div className={styles.lightBoard}>
          <div className={styles.lightBoardInner}>
            <div className={styles.graphWrapper}>
              <Image
                src={candles.src}
                alt="Capex Platform Candles"
                width="0"
                height="0"
                sizes="100vw"
                className={styles.candlesImg}
              />
            </div>
            <Image
              src={goldTab.src}
              alt="Capex Platform Light"
              width="0"
              height="0"
              sizes="100vw"
              className={styles.goldTabImg}
            />
            <Image
              src={lightBoard.src}
              alt="Capex Platform Light"
              width="0"
              height="0"
              sizes="100vw"
              className={styles.lightBoardImg}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightSection;
